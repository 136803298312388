import {
  BackgroundColor,
  classNames,
  Container,
  ContainerWidth,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  Heading,
  JustifyContent,
  Link,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  TextDecoration,
} from '@snoam/pinata';
import * as React from 'react';
import {IWithWPContext, withWPContext} from '../../context/withWPContext';
import uniqueId from 'lodash/uniqueId';
import ReactHtmlParser from 'react-html-parser';
import {convertLinksToPinataStyle, isTest} from '../../utils';
import {SalesForceChat} from "@snoam/mono-sf-chat";
import {BoostChatbot} from "@snoam/mono-boost";
import {ClientShortHandName} from "@snoam/mono-scc";

export interface IFooterProps extends IWithWPContext {
  hostname: string;
  clientShorthandName: ClientShortHandName;
  clientName: string;
}

const styleClass = {
  info: classNames(
    BackgroundColor.BG_NEUTRAL_2,
  ),
  title: classNames(
    Padding.PY_4,
    TextAlign.TEXT_CENTER,
    FontFamily.FONT_TITLE_1,
  ),
  contactWrapper: classNames(
    Display.LG_FLEX,
    Padding.PT_10,
    Padding.PB_6,
    Padding.PX_4,
    Padding.MD_PX_0,
  ),
  contactTitle: classNames(
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_LG,
    Margin.MB_3,
  ),
  contactContent: classNames(
    FontFamily.FONT_BODY,
    FontSize.TEXT_SM,
  ),
  linkItem: classNames(
    TextColor.TEXT_NEUTRAL_6,
    TextDecoration.NO_UNDERLINE,
    Padding.PY_2,
  ),
  links: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    FlexDirection.MD_FLEX_ROW,
    JustifyContent.LG_JUSTIFY_BETWEEN,
    Padding.PY_4,
    Padding.MD_PY_10,
    TextAlign.TEXT_CENTER,

  ),
  linkWrapper: classNames(
    BackgroundColor.BG_NEUTRAL_2,
  ),
};

const Footer: React.FC<IFooterProps> = (props) => {
  const {contact} = props;
  const isProduction = !isTest();

  const renderContactInfo = (contact) => {
    if(contact.length > 0) {
      return contact.map(contactItem => {
        return (
          <div key={uniqueId('contact-')} className={classNames(Padding.PR_6, Padding.PB_4, Padding.MD_PB_4)}>
            <Heading className={styleClass.contactTitle}>{contactItem.title}</Heading>
            <div className={styleClass.contactContent}>
              {ReactHtmlParser(contactItem.content, {decodeEntities: true, transform: convertLinksToPinataStyle})}
            </div>
          </div>
        )
      });
    }
    return;
  };

  const showPrivacyManager = () => window._tcf_?.showPrivacyManager();

  const renderFooterMenu = () => {
    const { footerMenu } = props;
    return (
      <>
        {footerMenu.map((item) => {
          return (
            <Link
              href={item.url}
              className={styleClass.linkItem}
              key={uniqueId("footer-menu-item-")}
            >
              {item.title}
            </Link>
          );
        })}
        <p className={styleClass.linkItem} style={{cursor: 'pointer'}} onClick={showPrivacyManager}>
          Cookieinnstillinger
        </p>
      </>
    );
  };
  

  const renderChat = () => {
    if([ClientShortHandName.AP, ClientShortHandName.VG, ClientShortHandName.BTI, ClientShortHandName.SA].includes(props.clientShorthandName)) {
      return (
        <BoostChatbot
          // @ts-ignore
          clientShortHandName={props.clientShorthandName}
          isProduction={isProduction}
        />
      )
    }
    const alternativeChat = props.clientShorthandName === ClientShortHandName.DP ? ClientShortHandName.E24 : props.clientShorthandName;
    return (
      <SalesForceChat
        clientShortHandName={alternativeChat}
        isProduction={isProduction}
      />
    )
  }

  return (
    <footer>
      <div className={styleClass.info}>
        <Container width={ContainerWidth.WIDE} >
          <section className={styleClass.contactWrapper}>
            {renderContactInfo(contact)}
          </section>

        </Container>
      </div>

      <div className={styleClass.linkWrapper}>
        <Container className={styleClass.links} width={ContainerWidth.WIDE}>
          {renderFooterMenu()}
        </Container>
      </div>

      {renderChat()}

    </footer>
  );

}

export default withWPContext(Footer);
