import React from 'react';
import {IWithPulseEvent, withPulseEvent} from "../../tracking/withPulseEvent";
import PulseEvent from "../../tracking/PulseEvent";
import {EventType, ObjectType} from "@snoam/mono-pulse";
import { EventBuilders } from '@spt-tracking/pulse-sdk';


export interface IPageViewEventProps extends IWithPulseEvent {
  pageId: string;
  eventLabel: string;
}

class PageViewEvent extends React.Component<IPageViewEventProps> {

  shouldComponentUpdate() {
    return false;
  }

  render() {

    const {pulse, pageId, eventLabel} = this.props;
    const model = pulse.createPageViewModel({
      labelPrefix: "KS",
      label: eventLabel,
      type: ObjectType.PAGE,
      id: pageId,
    });

    return <PulseEvent model={model}/>
  }
}

export default withPulseEvent(PageViewEvent);
