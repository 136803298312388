import { EngagementAction, getInstance } from "@snoam/mono-pulse";
import { EventBuilders } from "@spt-tracking/pulse-sdk";


export function trackEngagement(eventLabel: string, targetId: string) {
  const pulse = getInstance();
  
  const model = pulse.createEngagementModel({
    labelPrefix: "KS",
    label: eventLabel,
    action: EngagementAction.Click,
    id: targetId,
    pageId: "kundeservice",
    elementType: "Button",
  });

  return pulse.track(model);
}
